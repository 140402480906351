.font_h1 {
  font-size: 34px;
  line-height: 38px;
}
.font_h2 {
  font-size: 30px;
  line-height: 34px;
}
.font_h3 {
  font-size: 26px;
  line-height: 28px;
}
.font_h4 {
  font-size: 18px;
  line-height: 20px;
}
.font_h5 {
  font-size: 16px;
  line-height: 18px;
}
.font_h6 {
  font-size: 14px;
  line-height: 16px;
}

.font_h7 {
  font-size: 12px;
  line-height: 14px;
}

.font_h8 {
  font-size: 10px;
  line-height: 12px;
}

@media screen and (max-width: 759px) {
  .font_h1 {
    font-size: 34px;
    line-height: 38px;
  }
  .font_h2 {
    font-size: 30px;
    line-height: 34px;
  }
  .font_h3 {
    font-size: 26px;
    line-height: 28px;
  }
  .font_h4 {
    font-size: 18px;
    line-height: 20px;
  }
  .font_h5 {
    font-size: 16px;
    line-height: 18px;
  }
  .font_h6 {
    font-size: 14px;
    line-height: 16px;
  }
  
  .font_h7 {
    font-size: 12px;
    line-height: 14px;
  }
  
  .font_h8 {
    font-size: 10px;
    line-height: 12px;
  }
}

.font_black {
  font-weight: bold;
  font-style: normal;
}
.font_black_italic {
  font-weight: bold;
  font-style: italic;
}
.font_extra_bold {
  font-weight: 900;
}
.font_extra_bold_italic {
  font-weight: 900;
  font-style: italic;
}
.font_bold {
  font-weight: 800;
}
.font_bold_italic {
  font-weight: 800;
  font-style: italic;
}

.font_semi_bold {
  font-weight: 600;
}
.font_semi_bold_italic {
  font-weight: 600;
  font-style: italic;
}

.font_regular {
  font-weight: 400;
}
.font_regular_italic {
  font-weight: 400;
  font-style: italic;
}

.font_light {
  font-weight: 300;
}
.font_light_italic {
  font-weight: 300;
  font-style: italic;
}

.font_extra_light {
  font-weight: 200;
}
.font_extra_light_italic {
  font-weight: 200;
  font-style: italic;
}
