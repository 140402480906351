.video--wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  
  }

  .container--mobile {
    @media (max-width: 800px) {
      position: fixed;
      z-index: 99;
      height: 100%;
      -webkit-overflow-scrolling: touch;  
      min-height: -webkit-fill-available;
      width: 100%;
  }
}

  /* Container */
  #Dish {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    background-color: #000000;
    position: absolute;
    left: 80px;
    bottom: 0px;
    top: 0px;
    right: 0px;
    @media (max-width: 800px) {
      left: 0;
    }
  
    .call--section--operator {
      position: absolute;
      bottom: 5%;
      right: 5%;
      z-index: 9999999;
      @media (max-width: 800px) {
        bottom: 15%;
        right: 2%;
      }
  
      video {
        border-radius: 100px;
        width: 120px;
        height: 120px;
        object-fit: cover;
        border: 1px solid #fefefe;
  
        @media (max-width: 800px) {
          width: 80px;
          height: 80px;
        }
      }
    }
  
    .call--section--video {
      height: 100vh;
      @media (max-width: 800px) {
        height: 90vh;
      }
      video {
        width: 100%;
        object-fit: cover;
  
        @media (max-width: 800px) {
          border-radius: 10px;
        }
      }
  
      img {
        width: 100%;
      }
    }
  }
  #Dish .Camera {
    position: relative;
    vertical-align: middle;
    align-self: center;
    overflow: hidden;
    display: inline-block;
    background: rgb(159, 159, 159);
    animation: show 0.4s ease;
  
    video {
      object-fit: cover;
    }
  }
  
  /* Animation */
  @keyframes show {
    0% {
      opacity: 0;
      transform: scale(0.4) translateY(20px);
    }
    100% {
      opacity: 1;
      transform: scale(1) translateY(0);
    }
  }
  