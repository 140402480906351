.claims__section {
  &__infos {
      &__p1 {
          &__title {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              h2 {
                  margin-right: 3px;
                  font: normal normal 600 20px/27px Nunito;
              }
          }
          &__subtitles {
              h5 {
                  padding: 2px 0;
              }
              &__label {
                  font: normal normal 300 16px/19px Nunito;
              }
          }
      }
      &__p2 {
          display: flex;
          justify-content: center;
          &__title {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              h2 {
                  margin-right: 1px;
                  font: normal normal 600 20px/27px Nunito;
              }
          }
      }

      &__p3 {
          display: flex;
          justify-content: center;
          &__title {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: flex-start;
              h2 {
                  margin-right: 1px;
                  font: normal normal 600 20px/27px Nunito;
              }
          }

          &__sub {
              h4 {
                  padding: 3px 0;
                  font: normal normal 600 20px/27px Nunito;
              }
          }
      }
  }

  &__container {
      &__documents {
          background-color: $c-white;
          border-radius: 8px;
          padding: 12px;
          position: relative;
          height: 65vh;
      }
      &__chat {
          background-color: $c-white;
          border-radius: 8px;
          padding: 12px;
          position: relative;
          height: 65vh;

      }
  }

}

