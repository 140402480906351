.call--section {
  width: 100%;
  position: relative;
  @media (max-width: 800px) {
    padding: 10px 0;
  }

  &--actions {
    position: absolute;
    bottom: 5%;
    left: 0;
    right: 0;
    width: 300px;
    width: -moz-max-content;
    width: -webkit-max-content;
    width: -o-max-content;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 100px;
    padding: 8px 18px;
    @media (max-width: 800px) {
      padding: 6px 10px;
      width: 220px;
    }
    @media (max-height: 700px) {
      padding: 6px 10px;
      width: 220px;
    }

    &--btn {
      margin-right: 20px;
      @media (max-width: 800px) {
        margin-right: 10px;
        min-width: 30px !important;
        height: 30px;
        line-height: 14px;
      }
      @media (max-height: 700px) {
        margin-right: 10px;
        min-width: 30px !important;
        height: 30px;
        line-height: 14px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .ant-btn-bordered {
      background-color: #dedede;
    }
  }

  &--operator {
    position: absolute;
    bottom: 5%;
    right: 5%;
    z-index: 9999999;
    @media (max-width: 800px) {
      bottom: 15%;
      right: 2%;
    }
    border-radius: 100px;
    width: 120px;
    height: 120px;
    background-color: "black";
    video {
      border-radius: 100px;

      object-fit: cover;
      border: 1px solid #fefefe;

      @media (max-width: 800px) {
        width: 80px;
        height: 80px;
      }
    }
  }

  &--video {
    height: 100vh;
    @media (max-width: 800px) {
      height: 90vh;
    }
    video {
      width: 100%;
      object-fit: cover;

      @media (max-width: 800px) {
        border-radius: 10px;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: 800px) {
        border-radius: 10px;
      }
    }
  }
}

.record--area {
  position: absolute;
  z-index: 9999;
  right: 2%;
  top: 2%;
  background-color: white;
  border-radius: 6px;
  border-top: 5px solid #00bdbb;
  padding: 10px;

  @media (max-width: 800px) {
    padding: 6px;
  }
}

.record--btn {
  background-color: #172975;
  color: white;
  border: none;

  @media (max-width: 800px) {
    font-size: 12px;
  }

  &:hover {
    background-color: #00bdbb;
    color: white;
  }
}
