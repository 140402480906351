
.ant-table {
  background: white;
}
.ant-table-thead > tr > th {
  background: #EBF0FF;
  border-bottom: 1px solid #d5def9;
}

.ant-table-thead tr  th  .ant-table-column-title{
  color: #112B75;
  font-size: 13px;
  font-weight: 800 !important;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: rgba(#EBF0FF, .5);
}

.button__chat {
  cursor: pointer;
}


.duplicate__circle {
  background-color: $c-second;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 5px;
}