.section--login {
    display: grid;
    place-items: center;
    height: max-content;
    width: 40%;
    background: white;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);

    @media (max-width: 1000px) {
        width: 50%;
    }
    @media (max-width: 800px) {
        width: 80%;
    }
    @media (max-width: 500px) {
        width: 96%;
    }

    &--title {
        font-size: 26px;
        font-weight: 800;
        margin-bottom: 20px;
    }

    &--form {
        width: 100%;

        &--button {
            color: $c-white;
            background-color: $c-main;
            border-color: $c-main;
            width: 100%;
            height: 45px;

            &:hover {
                background-color: $c-second;
            }
        }

        &--input {
            background-color: $c-white;
            height: 45px;
            margin-bottom: 10px;
        }
    }
  }