.form-container{
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  .title{
    font: normal normal medium 24px/24px Rubik;
    color: #112B75;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .sub-title{
    font: normal normal medium 24px/24px Rubik;
    color: #222;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 24px;
  }
}
