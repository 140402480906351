@import '../partials/variables';

.chart-container{
  background-color: white;
  box-shadow: 0 2px 6px #0000000A;
  border-radius: 8px;
  padding: 26px 21px;
}

.chart-legend{
  display: flex;
  flex-direction: row;
  margin-bottom: 31px;
  p{
    margin-left: 10px;
    margin-right: 70px;
    flex-direction: row;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #4D4F5C;
  }
  p::before{
    content: "";
    display: block;
    height: 10px;
    width: 18px;
    border-radius: 5px;
    margin-right: 10px;
  }
  .calls::before{
    background-color: $c-main;
  };
  .total::before{
    background-color: $c-gray-d9;
  };
}
