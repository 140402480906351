.auth-container{
  background-color: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding:30px 40px;
  width: 100%;
  -webkit-box-shadow: -1px 2px 17px 3px rgba(0,0,0,0.06);
  -moz-box-shadow: -1px 2px 17px 3px rgba(0,0,0,0.06);
  box-shadow: -1px 2px 17px 3px rgba(0,0,0,0.06);
  .auth-title{
    font: normal normal medium 34px/41px Rubik;
    font-weight: 600;
    color: #112B75;
    font-size: 34px;
  }
  .auth-subtitle{
    font: normal normal medium 16px/22px Rubik;
    font-weight: 500;
    font-size: 16px;
    color: #FE5D80;
    margin-bottom: 30px;
  }
  .auth-input-container{
    margin-bottom: 25px;
  }
  .auth-forgot-pwd{
    color:#FE5D80;
    font: normal normal medium 16px/22px Rubik;
    font-size: 16px;
    margin-top: 26px;
    cursor: pointer;
  }
}
