.ant-modal-body {
  padding: 16px;
}
.modal--container {
  padding: 15px 10px;

  @media (max-width: 800px) {
    padding: 5px 0;
  }

  hr.divider {
    border-top: 1.8px solid rgb(244, 244, 244) !important;
  }

  &--head {
    h2 {
      font-size: 20px;
      font-weight: 800;
      color: $c-main;
    }
  }

  &--subhead {
    padding: 0 0 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 700px) {
      flex-direction: column;
      padding: 0 0 5px 0;
    }

    h3 {
      font-size: 18px;
      font-weight: 700;
      color: $c-main-dark;
      @media (max-width: 800px) {
        padding-bottom: 10px;
      }
    }

    &--right {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      @media (max-width: 800px) {
        justify-content: center;
      }

      &--item {
        margin-right: 10px;
        border-radius: 50px;
        height: 40px;
        padding: 0 13px;
        cursor: pointer;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &--status {
        text-align: center;
        background-color: #e0f7f7;
        color: #00bdbb;
        p {
          font-weight: 700;
        }
      }
      &--edit {
        background-color: #e0f7f7;
        color: rgb(0, 189, 142);
      }
      &--delete {
        background-color: rgb(247, 224, 229);
        color: rgb(189, 0, 0);
      }
      &--time {
        background-color: #f2f2f2;
        text-align: center;
        color: #172975;
        display: flex;
        align-items: center;
        flex-direction: row;
        p {
          font-weight: 600;
          margin-left: 6px;
        }
      }
      &--call {
        background-color: #f2f2f2;
        text-align: center;
        color: rgb(189, 0, 0);
      }
    }
  }

  &--date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--item {
      background-color: #f9f9f9;
      border: 1px solid #eaeaea;
      padding: 15px;
      border-radius: 6px;
      width: 49%;

      p {
        font-size: 13px;
        font-weight: 600;
        color: #636363;

        strong {
          color: #172975;
          font-weight: 700;
        }
      }
    }
  }

  &--infos {
    &--item {
      background-color: #f9f9f9;
      border: 1px solid #eaeaea;
      padding: 15px;
      border-radius: 6px;
      p {
        font-size: 13px;
        font-weight: 600;
        color: #636363;
        line-height: 20px;

        strong {
          color: #172975;
          font-weight: 700;
        }
      }

      &--desc {
        color: #1a1919 !important;
      }
    }
  }

  &--form {
    padding: 15px 0;
    @media (max-width: 700px) {
      padding: 0 0 5px 0;
    }
    &--input {
      background-color: #f9f9f9;
      border-radius: 6px;
      color: $c-main;
      border: 1px solid #eaeaea;
      height: 50px;
      font-weight: 700;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 11px;
    }
  }

  &--operators {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    height: 200px;
    overflow-x: auto;

    &--item {
      width: 33.333%;
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 8px;
      cursor: pointer;

      @media (max-width: 800px) {
        width: 50%;
      }

      @media (max-width: 500px) {
        width: 100%;
      }

      &:hover {
        background-color: #f7f7f7;
        border-radius: 6px;
      }

      &--img {
        border-radius: 100px;
        width: 45px;
        height: 45px;
      }

      &--infos {
        margin-left: 10px;

        h4 {
          font-size: 14px;
          font-weight: 800;
          color: $c-main;
          padding-bottom: 6px;
        }
        h5 {
          font-size: 12px;
          font-weight: 800;
          color: #a1a0ae;
        }
      }
    }
  }

  &--footer {
    // padding-top: 15px;
    h5 {
      font-size: 18px;
      font-weight: 700;
      color: $c-main-dark;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      color: #6a6a6a;
      padding: 10px 0;
    }
    &--copy {
      background-color: #f1f3f4;
      padding: 15px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 800px) {
        padding: 10px;
      }

      @media (max-width: 500px) {
        padding: 10px;
      }

      &--p {
        font-size: 16px;
        font-weight: 700;
        color: $c-black;
        cursor: text;
        display: block;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 800px) {
          font-size: 12px;
        }

        @media (max-width: 500px) {
          font-size: 12px;
        }
      }

      svg {
        font-size: 20px;
        color: #606367;
        cursor: pointer;
      }
    }

    &--button {
      width: 100%;
      &--btn {
        background-color: $c-main;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 24px 64px;
        margin: 0 auto;

        @media (max-width: 800px) {
          padding: 18px 32px;
        }

        @media (max-width: 500px) {
          padding: 12px 24px;
        }

        &:hover {
          background-color: $c-second;
          color: #fff;
          border-color: $c-second;
        }
      }
    }
  }
}

.ant-picker-input {
  input {
    color: $c-main;
    font-weight: 700;
  }
}
