.calendar--container {
    padding: 20px 10px;
    -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    background-color: white;
    border-radius: 6px;

    &--title {
        font-size: 20px;
        font-weight: 700;
        padding-bottom: 20px;
        color: $c-main;
   
    }
}

.rbc-event {
    background-color: #DDF1F4 !important;
    border: none !important;
    border-left: 3px solid #00BDBB !important;
    border-radius: 0 !important;
    color: $c-main-dark !important;

    &:first-child {
        margin-left: 0 !important;
    }
}

.rbc-toolbar .rbc-active {
    background-color: $c-second !important;
    color: white !important;
    border: 1px solid $c-second !important;;
}
.rbc-toolbar .rbc-active:focus {
    outline: none !important;
}

.rbc-today {
    background-color: #f0f0f0 !important;
}

.rbc-time-content > * + * > * {
    border-left: 1px solid #dfe6eb !important;
}

.rbc-timeslot-group {
    border-bottom: 1px solid #dfe6eb !important;
    height: 50px;
}

.rbc-time-content {
    border-top: 2px solid #dfe6eb !important;
}

.rbc-day-slot .rbc-event-content {
    font-size: 12px;
}

@media (max-width: 800px) {
    .rbc-btn-group {
        padding-bottom: 10px;
    }
    .rbc-toolbar-label {
        margin-bottom: 10px;
    }
}

.react-datetime-picker__wrapper {
    border: none
}