.attachments__item:hover > div {
  display: block;
}

.attachments__item {
  background-color: $c-white;
  -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 10px;
  position: relative;

  &__actions {
    display: none;
    position: absolute;
  }
  &__image {
    height: 200px;
    width: 100%;
    img {
      border-radius: 10px;
      height: 100%;
      width: 100%;
    }
  }

  &__infos {
    &__codes {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: row;
      margin-bottom: 3px;
      span {
        font-size: 10px;
        padding: 0 1px;
      }
    }

    &__title {
      text-align: center;
    }
  }

  &__status {
    position: absolute;
    top: 4%;
    right: 4%;
  }
}

.attachments__item.status__approved {
  border: 1px solid #31be41;
}
.attachments__item.status__pending {
  border: 1px solid #ffd800;
}
.attachments__item.status__rejected {
  border: 1px solid #ce0044;
}
.attachments__item.status__selected {
  border: 1px solid #a3aeb9;
}

.modal__attachmentItem {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__title {
    &__codes {
      text-align: center;
      font-size: 21px;
    }
  }
  &__image {
    margin-top: 20px;
    width: 100%;
    height: 100%;

    img {
      border-radius: 10px;
      width: 100%;
      height: 100%;
    }
  }
}
