.primary_c {
  color: $c-main;
}

.primary_dark_c {
  color: $c-main-dark;
}

.second_c {
  color: $c-second;
}

.second_dark_c {
  color: $c-second-dark;
}

.red_c {
  color: $c-red;
}
.green_c {
  color: $c-green;
}

.black_c {
  color: $c-black;
}
.white_c {
  color: $c-white;
}
.gray_light_c {
  color: $c-gray-light;
}

.gray_c {
  color: $c-gray;
}


.gray_l2_c {
  color: $c-gray-l2;
}

.blue_dark_c {
  color: $c-blue-dark;
}

.bg-white {
  background-color: $c-white;
}

.bg-body {
  background-color: $c-body;
}

.bg-light-grey {
  background-color: $c-gray-light;
}

.bg-grey {
  background-color: $c-gray;
}

.bg-dark-pega {
  background-image: $c-main;
}

.bg-dark-pega {
  background-image: $c-second;
}
