.statistic_section {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 30px auto;

  &.statistic_item {
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-width: 1px;
    padding: 1rem;
  }

  .title-base {
    font-size: 0.9rem;
    line-height: 1.9rem;
    color: rgb(107, 114, 128);
  }

  .value-base {
    letter-spacing: -0.025em;
    font-size: 1.775rem;
    font-weight: 800;
    line-height: 2.4rem;
  }
}
